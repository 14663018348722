import { FC } from 'react';
import { RecoilRoot } from "recoil";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Examples from './components/Example';

// import Demo from './pages/demo';
// import Examples from "./pages/examples";

const App: FC = () => {
  return (
    <BrowserRouter>
     <Routes>
          <Route path="/" element={<Examples />}>
              <Route path=":id" element={<Examples />} />
          </Route>
          {/* <Route path="/demo" element={<Demo />} /> */}
      </Routes>
    
    </BrowserRouter>

  );
}

export default App;
